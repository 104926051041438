import React, { PureComponent } from 'react'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'
import Layout from 'antd/lib/layout'
import 'antd/lib/layout/style/css'

import styles from './Footer.module.css'
import { footerContext } from 'Config'

const { Footer } = Layout


class HomeFooter extends PureComponent {
	render() {
		const { mainWord, linkWord } = footerContext
		return (
			<Footer className={styles.footer}>
				<Row type="flex" justify="center">
					<div className={styles.footerDes}>
						<p>
						
							{
								window.location.hostname === "focross.net"
									?
									`©${new Date().getFullYear()} 第三极区块链科技有限公司 版权所有`
									:
									`©${new Date().getFullYear()} FIBOS FOUNDATION 版权所有`
							}
							{
								window.location.hostname === "focross.net"
									?
									<a target="_blank" href="http://www.beian.miit.gov.cn">{linkWord}</a>
									:
									null
							}
						</p>
					</div>
				</Row>
			</Footer>
		)
	}
}

export default HomeFooter
