const prefix = 'Home_';

const defaultConfigure = {
  ethereum: null,
  crossFoContractInstance: null,
  signer: null,
  fibos: null,
  account: null,
  notMainNet: false,
};

export default (state = defaultConfigure, action) => {
  switch (action.type) {
    case `${prefix}changeFieldValue`: {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    default: {
      return state;
    }
  }
};
