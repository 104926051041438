import usdtAbi from './abi/USDT';

import logoIcon from 'Assets/logo.svg';
import info1Bg from 'Assets/info1Bg.png';
import info1Pic from 'Assets/info1.png';
import info2Bg from 'Assets/info2Bg.png';
import info2Pic from 'Assets/info2Pic.png';
import advantagesBg from 'Assets/advantagesBg.png';
import expectationsBg from 'Assets/expectationsBg.png';

import adv_1 from 'Assets/adv_1.png';
import adv_2 from 'Assets/adv_2.png';
import adv_3 from 'Assets/adv_3.png';
import adv_4 from 'Assets/adv_4.png';

import exp_1 from 'Assets/exp_1.png';
import exp_2 from 'Assets/exp_2.png';

export const websiteName = 'cross.fo';
export const websiteLogo = logoIcon;

export const mainPageConfig = {
  banner: {
    label: 'FIBOS IBC — 去中心化异构跨链协议',
    label_translate: '无风险 × 无中心 × 无损',
  },
  info1: {
    content: '面对波动，稳定币是可靠的稳定资源，不用担心其价值上涨或下跌。',
    backgroundImg: info1Bg,
    picData: info1Pic,
  },
  info2: {
    content: '多种稳定币自由选择，更灵活，更丰富',
    tips: '兑换·支付·交易',
    backgroundImg: info2Bg,
    picData: info2Pic,
  },
  advantages: {
    label: '技术优势',
    data: [
      {
        type: 'info',
        label: '稳定',
        picData: adv_1,
        content: '去中心化跨链实现原网络资产到FIBOS链上稳定币的1:1映射',
      },
      {
        type: 'info',
        label: '安全',
        picData: adv_2,
        content: '跨链机制引入智能合约和矿工角色，实现真正去中心化跨链',
      },
      {
        type: 'info',
        label: '开放',
        picData: adv_3,
        content: '无准入限制，任何人都可以成为矿工，参与完成稳定币跨链',
      },
      {
        type: 'info',
        label: '透明',
        picData: adv_4,
        content: '基于智能合约，无中心化风险，跨链无需任何人为参与，可信',
      },
    ],
    backgroundImg: advantagesBg,
  },
  expectations: {
    label: '应用展望',
    data: [
      {
        type: 'info',
        label: '供应链金融',
        picData: exp_1,
        content: '搭建流通资产的信任基础，解决双方信任问题',
      },
      {
        type: 'info',
        label: '跨境支付',
        picData: exp_2,
        content: '随时交易、不受限制、费用低、速度快',
      },
      {
        type: 'waiting',
        label: '未来已来\n敬请期待\n...',
      },
    ],
    backgroundImg: expectationsBg,
  },
};

export const footerContext = {
  mainWord: `©${new Date().getFullYear()} FIBOS FOUNDATION 版权所有`,
  linkWord: '苏ICP备16055553号—4',
};

/* f**k eth */
/* & web3 */
export const network = {
  account: 'eosio.cross',
  protocol: process.env.NODE_ENV === 'production' ? 'http' : 'http',
  hostport: () => {
    return process.env.NODE_ENV === 'production' ? '8.217.199.211:8871' : '8.217.199.211:8871';
  },
};

// 1 mainnet
// 3 testnet ropsten
export const netId = process.env.NODE_ENV === 'production' ? '11155111' : '11155111';

// export const ethGasAPIUrl =
//   process.env.NODE_ENV === 'production'
//     ? 'https://ethgasstation.info/json/ethgasAPI.json'
//     : '/ethgasAPI'

export const ethGasAPIUrl = '/ethgasAPI';

export const ethDecimalNum = 1e18;
export const ethDecimal = 18;

export const ethTransferGasLimit = 22872;
export const gweiPerEth = 1000000000;

export const gasPre = 9;
export const AmountPre = 6;
export { default as crossFoAbi } from './abi/cross';

/* register && target address begin */
// mainnet
// 0x8cbd6dFDD2Cc917793746613A648c600AFB020b1
// testnet ropsten
// 0x4152e64d1d3944dd0f6d0893cbac90d0dda807f3
export const crossFoContractAddress =
  process.env.NODE_ENV === 'production'
    ? // ? '0x8cbd6dFDD2Cc917793746613A648c600AFB020b1'
      // : '0xe1a6c4793c0886803e7493665a10cbe3d43178ee'
      '0xfB6a499347C054FF56C82b8038bF79d331c21322'
    : '0xfB6a499347C054FF56C82b8038bF79d331c21322';

/* register && target address end */

/* used to tranfer begin */
// mainnet
// 0xdAC17F958D2ee523a2206206994597C13D831ec7
// testnet ropsten
// 0xfa85865bdc48bf96f1fe0f0cd6a86e0fd8c9360d
const erc20AddressUSDT = 'production'
  ? '0xdAC17F958D2ee523a2206206994597C13D831ec7'
  : '0xfa85865bdc48bf96f1fe0f0cd6a86e0fd8c9360d';

// mainnet
// 0x89d24A6b4CcB1B6fAA2625fE562bDD9a23260359
// testnet ropsten
// 0xca9f289a6f9ed9e3770ffb32dfe1a523385bef57
const erc20AddressDAI =
  process.env.NODE_ENV === 'production'
    ? '0x89d24A6b4CcB1B6fAA2625fE562bDD9a23260359'
    : '0xca9f289a6f9ed9e3770ffb32dfe1a523385bef57';

// mainnet
// 0x1c48f86ae57291f7686349f12601910bd8d470bb
// testnet ropsten
//
const erc20AddressUSDK =
  process.env.NODE_ENV === 'production'
    ? '0x1c48f86ae57291f7686349f12601910bd8d470bb'
    : '0x1c48f86ae57291f7686349f12601910bd8d470bb';

// mainnet
// 0xfED30AdBA4d6316402d9bc2fF6f00a833E15E368
// testnet ropsten
// 0xfED30AdBA4d6316402d9bc2fF6f00a833E15E368
const erc20AddressFO =
  process.env.NODE_ENV === 'production'
    ? '0x4c28B6fc460d9debf8d03f509460eff27C8E81Fc'
    : '0xfED30AdBA4d6316402d9bc2fF6f00a833E15E368';
/* used to tranfer end */

const usdtDecimalNum = 1e6;
const daiDecimalNum = 1e18;
const usdkDecimalNum = 1e18;
const foDecimalNum = 1e4;

export const crossEnabledList = ['USDT', 'DAI', 'ETH', 'USDK', 'FO'];
export const crossConfiguration = {
  USDT: {
    decimal: usdtDecimalNum,
    address: erc20AddressUSDT,
    abi: usdtAbi,
  },
  DAI: {
    decimal: daiDecimalNum,
    address: erc20AddressDAI,
    abi: usdtAbi,
  },
  USDK: {
    decimal: usdkDecimalNum,
    address: erc20AddressUSDK,
    abi: usdtAbi,
  },
  FO: {
    decimal: foDecimalNum,
    address: erc20AddressFO,
    abi: usdtAbi,
  },
};
