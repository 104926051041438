import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import Fibos from 'fibos.js';
import detectEthereumProvider from '@metamask/detect-provider';

import { ScreenLarge } from 'Components';

import Row from 'antd/lib/row';
import 'antd/lib/row/style/css';
import notification from 'antd/lib/notification';
import 'antd/lib/notification/style/css';
import Icon from 'antd/lib/icon';
import 'antd/lib/icon/style/css';

import { network, netId, crossFoAbi, crossFoContractAddress } from 'Config';
import { customizedConnect } from 'Utils';

import HomeRouter from 'Router';
import HomeHeader from './components/Header.js';
import HomeFooter from './components/Footer.js';

import * as actions from './Home.action';
import styles from './Home.module.css';

class Home extends PureComponent {
  static propTypes = {};

  constructor(props, context) {
    super(props, context);

    this.state = {};

    this.key = 'MetamaskStatus';
  }

  _goto = (pathname, ops) => {
    this.props.history.push({
      pathname,
      state: {
        ...ops,
      },
    });
  };

  realLeave = (event) => {
    const { isFresh } = this.props;

    if (!isFresh) {
      event.returnValue = '确认离开?';
    }
  };

  openNotification = (message, description, type, callback) => {
    if (message || description) {
      notification.open({
        key: this.key,
        className: styles.notification,
        message: message || '',
        description: description || '',
        duration: 15,
        onClick: (e) => {
          console.log('Notification Clicked!', e);
          if (callback) {
            callback();
          }
        },
        icon:
          type !== 'info' ? (
            <Icon type='frown' style={{ color: 'red' }} />
          ) : (
            <Icon type='smile' style={{ color: '#108ee9' }} />
          ),
        style: {
          width: 'auto',
        },
        placement: 'bottomLeft',
      });
    }
  };

  dealAddress = (account) => {
    const { changeFieldValue } = this.props;

    changeFieldValue('account', account);

    return this.openNotification(
      '您好',
      <div>
        <div>已连接到 Ethereum 主网</div>
        <br />
        <div
          style={{
            whiteSpace: 'normal',
            wordBreak: 'break-all',
            wordWrap: 'break-word',
          }}
        >{`账户地址: ${account}`}</div>
      </div>,
      'info'
    );
  };

  checkAccounts = async (ethereum) => {
    const provider = await detectEthereumProvider();

    if (provider) {
      provider
        .enable()
        .then((res) => {
          this.dealAccounts(res, provider);
        })
        .catch((err) => {
          this.openNotification('出错', '用户拒绝授予网站访问权限。', 'warning');
        });
    } else {
      const accounts = await ethereum.request({ method: 'eth_accounts' });
      this.dealAccounts(accounts, ethereum);
    }
  };

  dealAccounts = async (accounts, ethereum) => {
    const { changeFieldValue } = this.props;

    if (accounts.length === 0) {
      return this.openNotification('出错', '无可用账户。', 'warning');
    } else {
      const netVersion = ethereum.networkVersion;

      if (netVersion === netId) {
        this.dealAddress(accounts[0]);
        changeFieldValue('ethereum', ethereum);

        const abi = [
          'function register(uint64 name)',
          'function transfer(address to, uint amount)',
        ];
        const provider = new window.ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const crossFoContractInstance = new window.ethers.Contract(
          crossFoContractAddress,
          abi,
          provider
        );

        changeFieldValue('crossFoContractInstance', crossFoContractInstance);
        changeFieldValue('signer', signer);
      } else {
        const { changeFieldValue } = this.props;
        changeFieldValue('notMainNet', true);

        return this.openNotification('出错', '请连接到 Ethereum 主网', 'warning');
      }
    }
  };

  attachMetamask = () => {
    window.addEventListener('load', () => {
      const ethereum = window.ethereum || undefined;

      if (ethereum) {
        this.openNotification('您好', '已检测到 Ethereum 环境', 'info');

        if (!ethereum.isConnected()) {
          return this.openNotification('出错', '请设置环境连接到一个 Ethereum 网络节点', 'warning');
        } else {
          this.checkAccounts(ethereum);
        }
      } else {
        this.openNotification(
          '出错',
          <div>
            未检测到 Metamask 插件。请先
            <a
              href='https://github.com/MetaMask/metamask-extension/releases'
              className={styles.downloadLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              下载
            </a>
            并登录 MetaMask 插件。登录之后，刷新页面。
          </div>,
          'warning'
        );
      }
    });
  };
  attachFibosClient = () => {
    const { changeFieldValue } = this.props;

    const fibos = Fibos({
      chainId: '68cee14f598d88d340b50940b6ddfba28c444b46cd5f33201ace82c78896793a',
      httpEndpoint: `${network.protocol}://${network.hostport()}`,
    });

    changeFieldValue('fibos', fibos);
  };

  componentDidMount() {
    window.onunload = (event) => {
      return this.realLeave(event);
    };

    this.attachMetamask();
    this.attachFibosClient();
  }

  render() {
    const { location } = this.props;
    const isTransparentMode = location.pathname === '/' || location.pathname === '/index';

    return (
      <div className={isTransparentMode ? styles.mainContainerWithBg : styles.mainContainerWithBg2}>
        <ScreenLarge>
          {(matches) => {
            return (
              <div className={styles.container}>
                <Row type='flex' justify='center' className={styles.headerRow}>
                  <HomeHeader isTransparentMode={isTransparentMode} />
                </Row>
                <div className={styles.content}>
                  <div className={styles.contentBody}>
                    <Row type='flex' justify='center' className={styles.bodyRow}>
                      <HomeRouter />
                    </Row>
                  </div>
                </div>
                <HomeFooter />
              </div>
            );
          }}
        </ScreenLarge>
      </div>
    );
  }
}

export default customizedConnect('home', actions, withRouter(Home));
